.errorMessage {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 1;
  transition: opacity 200ms ease-in;
  pointer-events: auto;
}

.errorMessage > div {
  width: 80%;
  position: fixed;
  bottom: -4px;
  left: 5%;
  padding: 24px 16px 24px 16px;
  border-radius: 6px;
  background: #ff3238;
  font-size: 2rem;
  font-family: 'BebasNeue', sans-serif;
  font-weight: bold;
  text-align: center;
}

.errorMessageCloseButton {
  background-color: #55171d;
  color: white;
  font-family: sans-serif;
  line-height: 1;
  position: absolute;
  right: -12px;
  top: -16px;
  height: 32px;
  width: 32px;
  text-align: center;
  font-size: 1rem;
  border-radius: 16px;
  border: 3px solid #ff3238;
}
