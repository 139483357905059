div.photoContainer {
  text-align: center;
  overflow: hidden;
}

img.photoImage {
  position: relative;
  left: 100%;
  margin-left: -200%;
}
