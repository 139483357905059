@font-face {
  font-family: 'Papier';
  font-display: swap;
  src: url('./fonts/PApierSans.otf');
}

@font-face {
  font-family: 'BebasNeue';
  font-display: swap;
  src: url('./fonts/BebasNeueBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Archivo';
  font-display: swap;
  src: url('./fonts/Archivo.ttf');
}

@font-face {
  font-family: 'Archivo';
  font-display: swap;
  src: url('./fonts/ArchivoBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Archivo';
  font-display: swap;
  src: url('./fonts/ArchivoBoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

body {
  color: white;
  background-image: url('./images/mobileBackground.png');
  background-color: #8a2e35;
  background-size: cover;
  font-family: 'Papier', cursive;
}

div.content {
  display: flex;
  flex-direction: column;
}

input[type='file'] {
  display: none;
}

label.file-upload {
  display: inline-block;
  cursor: pointer;
}

.fullWidthRow {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.languageSwitchers {
  margin: 1rem 0;
}

.languageSwitcher {
  opacity: 0.5;
  outline: none;
}

.languageSwitcher > img {
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  border: 2px solid white;
}

.languageSwitcher.currentLocale {
  opacity: 1;
}

.GtuTitle {
  font-family: 'BebasNeue', sans-serif;
  color: #ff3238;
  font-size: 1.7rem;
  margin: 1rem 0 0 0;
}

#modalGtu {
  color: black;
  font-family: 'Archivo', sans-serif;
  font-weight: normal;
  font-size: 0.9rem;
}

#modalGtu b {
  display: block;
  padding: 4px 0;
}

.thanksPage {
  text-align: center;
  white-space: nowrap;
}

.uploadSuccessfull {
  width: 86vw;
  padding: 16vw 8vw;
}

.thanksPage .thanksMessage {
  font-size: 16vw;
  padding: 2vw 0;
  line-height: 1em;
  font-family: 'Papier', cursive;
}

img.logoFuturoscope {
  width: 50%;
  margin: 0 auto 2rem;
}

div.roundButton {
  background-color: #ff3238;
  border: 0.3rem solid white;
  border-radius: 50%;
  text-align: center;
  width: 7em;
  height: 7em;
}

div.photoPreview {
  padding: 1rem 0;
}
