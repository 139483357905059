.labelCGU {
  font-family: 'Papier', cursive;
  font-size: 1.1em;
}

.labelCGU.error,
.labelCGU.error > a {
  color: #ff3238;
}

div.uploadInProgress {
  padding-top: 2rem;
}

input[disabled] {
  opacity: 0.3;
}

form.mobileForm {
  text-align: center;
  font-family: 'BebasNeue', sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
}

form.mobileForm input,
form.mobileForm select {
  color: black;
  border-width: 0;
  margin-bottom: 2rem;
  outline: none;
}

form.mobileForm input {
  text-align: center;
  height: 1.6rem;
  padding: 0.3rem;
  font-family: 'Archivo', sans-serif;
}

form.mobileForm input.error,
form.mobileForm select.error,
input[type='checkbox'] + label.error > span {
  background-color: #ff3238;
}

form.mobileForm select {
  background-color: white;
  padding: 0.3rem 0.3rem 0;
  border-radius: 0;
  border-width: 0;
}

form.mobileForm label {
  margin-bottom: -1rem;
}

form.mobileForm label.withBorder {
  margin: 0;
  border: 2vw solid #55171d;
}

#codeInput {
  width: 3em;
}

form.mobileForm input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label > span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  background-color: white;
  position: relative;
}

input[type='checkbox']:checked + label > span:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 5px;
  background: transparent;
  top: 5px;
  left: 4px;
  border: 3px solid #55171d;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}

form.mobileForm a {
  color: white;
}

div.sendRow {
  margin: 2rem 0 1rem;
}

div.formButton {
  background-color: #ff3238;
  border: 0.3rem solid white;
  border-radius: 50%;
  text-align: center;
  width: 6rem;
  height: 6rem;
  line-height: 6.4rem;
  outline: none;
}

div.formButton.disabled {
  opacity: 0.3;
}
