/*!
 * Inpired by
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.loader,
.loader > div {
  position: relative;
  box-sizing: border-box;
}
.loader {
  display: block;
  font-size: 0;
  color: #ff3238;
  margin: 32px auto;
}
.loader > div {
  display: inline-block;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.loader {
  width: 48px;
  height: 48px;
}
.loader > div {
  width: 48px;
  height: 48px;
  background: transparent;
  border-width: 4px;
  border-bottom-color: transparent;
  border-radius: 100%;
  animation: loader-rotate 0.72s linear infinite;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
