.modalDialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  opacity: 0;
  transition: opacity 200ms ease-in;
  pointer-events: none;
}

.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}

.modalDialog > div {
  width: 70vw;
  max-height: 80vh;
  position: relative;
  margin: 15% auto;
  padding: 12px 16px;
  border-radius: 0;
  background-color: white;
  text-align: center;
}

.modalContent {
  max-height: 80vh;
  overflow-y: scroll;
}

.modalCloseButton {
  background-color: #ff3238;
  color: white;
  line-height: 1.7;
  position: absolute;
  right: -17px;
  top: -17px;
  width: 34px;
  height: 34px;
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 21px;
  border: 3px solid white;
}
