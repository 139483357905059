div.welcome > img.participezA {
  padding-top: 1rem;
  width: 84vw;
  margin-left: 8vw;
}

div.welcome > .instructions {
  margin: 0;
  padding: 1rem 2rem;
  font-size: 9vw;
  list-style-type: none;
}

div.welcome > .instructions > li {
  counter-increment: step-counter;
  line-height: 1em;
  margin-bottom: 0.6em;
  position: relative;
  padding-left: 0.8em;
}

div.welcome > .instructions > li:before {
  font-family: 'BebasNeue', sans-serif;
  font-weight: bold;
  content: counter(step-counter) '.';
  color: #ff3238;
  position: absolute;
  left: 0;
}

img.camera {
  width: 30vw;
}
